<template>
  <Sheet :open="open" @update:open="closeAddvendor">
    <SheetContent
      :custom-z-index="selectedBill ? 'z-[100000000]' : 'z-[50]'"
      :class="selectedBill ? 'z-[100000000]' : 'z-[50]'"
      class="fixed w-full px-0 sm:min-w-[430px]"
    >
      <div v-if="open">
        <template v-if="invoiceFiles.length > 0">
          <VendorInvoicePreview :files="invoiceFiles"
        /></template>
      </div>
      <SheetHeader class="border-b px-6 pb-5">
        <SheetTitle class="text-lg font-bold text-primary">
          <span v-if="isLocal">{{
            shouldCreateOrUpdateVendor === 'create' ? 'Add' : 'Update'
          }}</span>
          <span v-else>Add</span>
          vendor</SheetTitle
        >
      </SheetHeader>

      <Tabs v-model="formType" class="">
        <div class="px-4 lg:px-6">
          <TabsList class="mt-5 w-full">
            <TabsTrigger value="local" class="w-1/2" @click="isLocal = true">
              Local
            </TabsTrigger>
            <TabsTrigger
              value="international"
              class="w-1/2"
              @click="isLocal = false"
            >
              International
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="local">
          <local-supplier-form
            :should-create-or-update-vendor="shouldCreateOrUpdateVendor"
            @form-data="localData = $event"
            @validation-result="
              (val) => (localFormPayeeConfirmationResult = val)
            "
            @proof_of_account_file="(val) => (proofOfAccountLocal = val)"
            @force_allow="(val) => (forceAllowLocalVendorCreation = val)"
          />
        </TabsContent>
        <TabsContent value="international">
          <international-supplier-form
            :error-message="errorMessage"
            @child-option="data = $event"
          />
        </TabsContent>
      </Tabs>
      <SheetFooter
        class="absolute bottom-0 left-0 z-10 w-full bg-white px-4 lg:px-6"
      >
        <div class="flex w-full flex-col items-center gap-x-2 border-t py-6">
          <SheetClose v-if="false" as-child>
            <Button type="button" variant="outline" class="w-full"
              >Cancel</Button
            >
          </SheetClose>
          <div
            v-if="isLocal && localFormPayeeConfirmationResult"
            class="mb-3 flex w-full items-center gap-2 px-2 py-3 text-sm text-primary"
            :class="{
              'bg-[#F6FEF9]': localFormPayeeConfirmationResult.is_full_match,
              'bg-[#FFFBFA]':
                !localFormPayeeConfirmationResult.is_full_match &&
                !localFormPayeeConfirmationResult.is_partial_match,
              'bg-[#FFFCF5]':
                !localFormPayeeConfirmationResult.is_full_match &&
                localFormPayeeConfirmationResult.is_partial_match,
            }"
          >
            <span class="flex-shrink-0">
              <ph-check-circle
                v-if="localFormPayeeConfirmationResult.is_full_match"
                weight="fill"
                :size="28"
                class="text-[#32D583]"
              />
              <ph-x-circle
                v-else-if="
                  !localFormPayeeConfirmationResult.is_full_match &&
                  !localFormPayeeConfirmationResult.is_partial_match
                "
                weight="fill"
                :size="28"
                class="text-[#F04438]"
              />

              <ph-warning-circle
                v-else-if="
                  !localFormPayeeConfirmationResult.is_full_match &&
                  localFormPayeeConfirmationResult.is_partial_match
                "
                class="text-[#F97316]"
                weight="fill"
                :size="28"
              />
            </span>

            <div>
              <p
                v-if="
                  !localFormPayeeConfirmationResult.is_full_match &&
                  !localFormPayeeConfirmationResult.is_partial_match
                "
                class="font-bold text-primary"
              >
                We could not verify this account
              </p>
              <p>{{ localFormPayeeConfirmationResult.description }}</p>
            </div>
          </div>

          <div v-if="showConsentStatement" class="mb-4 flex w-full px-4">
            <BaseCheckBox
              v-model="consentAgreed"
              class="flex items-start gap-3 text-sm text-primary"
            >
              <span>
                I understand the risks of adding this vendor with unverified
                account details and wish to continue
              </span>
            </BaseCheckBox>
          </div>
          <Button
            v-if="isLocal"
            :disabled="
              forceAllowLocalVendorCreation === false &&
              (!localData ||
                isLoading ||
                !localFormPayeeConfirmationResult ||
                (showConsentStatement && !consentAgreed) ||
                (showConsentStatement && !proofOfAccountLocal))
            "
            type="submit"
            class="w-full"
            @click.prevent="handleCreateUpdateVendor"
          >
            <ph-spinner v-if="isLoading" :size="20" class="mr-2 animate-spin" />
            {{ shouldCreateOrUpdateVendor === 'create' ? 'Add' : 'Update' }}
            vendor
            <span
              v-if="
                localFormPayeeConfirmationResult &&
                (!localFormPayeeConfirmationResult.is_full_match ||
                  localFormPayeeConfirmationResult.is_partial_match)
              "
            >
              &nbsp;anyway
            </span>
          </Button>

          <Button
            v-if="!isLocal"
            :disabled="isLoading || !data?.invoice_url"
            type="submit"
            class="w-full"
            @click.prevent="submitForm"
            >Add vendor
          </Button>
        </div>
      </SheetFooter>
    </SheetContent>
  </Sheet>
  <BaseModal
    :show="successModalIsOpen"
    size="sm"
    :show-heading="false"
    padding="p-0"
    @close-modal="onCloseSuccessModal"
  >
    <div class="overflow-hidden pb-8 text-center text-primary">
      <div class="flex h-36 items-center justify-center rounded-lg">
        <LottieAnimation :animation-data="SuccessJSON" class="h-full" loop />
      </div>

      <div>
        <h3 class="text-lg font-semibold">You vendor was added successfully</h3>
      </div>

      <div class="mt-7 px-12">
        <Button class="w-full" @click="onCloseSuccessModal">Dismiss</Button>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { LottieAnimation } from 'lottie-web-vue'
import {
  PhXCircle,
  PhCheckCircle,
  PhSpinner,
  PhWarningCircle,
} from '@phosphor-icons/vue'
import { useAuthStore } from '~/stores/auth/index'
import { useProfileStore } from '@/stores/profile'
import { useBillsStore } from '@/stores/bills'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import LocalSupplierForm from '@/components/modules/vendors/local-supplier-form.vue'
import VendorInvoicePreview from '@/components/modules/vendors/vendor-invoice-preview.vue'
import InternationalSupplierForm from '@/components/modules/vendors/international-supplier-form.vue'
import { useToast } from '@/components/ui/toast/use-toast'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import type { Data } from '~/plugins/eventBus'
import { setModalState } from '@/services/modal'
import { LeadBoosterController } from '~/lib/utils'
import { LOCAL_STORAGE_KEYS } from '~/constants/CONSTANTS'
import type { Beneficiary } from '~/shared/interfaces'
import SuccessJSON from '@/assets/lottie-files/success.json'
import type { ConfirmPayeeDetailsResponse } from '~/types/apiResponse/confirmationOfPayee.response'

const { onOpenSuccessModal, onCloseSuccessModal, successModalIsOpen } =
  useDisclosure('successModal')

const profile = useProfileStore()
const isLocal = ref(true)
const localData = ref()
const errorMessage = ref('')
const data = ref()
const isLoading = ref(false)
const createBillVendor = ref(false)
const ocrVendorDetails = ref<Beneficiary | null>(null)
const invoiceFiles = ref<(File | null)[]>([])
// const isCreated = ref(false)

const { toast } = useToast()

const { $event, $listen, $lenkieBankingApi, $api } = useNuxtApp()

const auth = useAuthStore()
const billStore = useBillsStore()
const shouldCreateOrUpdateVendor = ref<'create' | 'update'>('create')

const { personId } = storeToRefs(auth)

const props = defineProps<{ open: boolean }>()

$listen(
  'open:vendor',
  ({ trigger, createNewVendorForBill, updateVendorType }: Data) => {
    if (trigger && createNewVendorForBill) {
      createBillVendor.value = createNewVendorForBill
      if (updateVendorType && updateVendorType?.type === 'update') {
        shouldCreateOrUpdateVendor.value = updateVendorType.type
        ocrVendorDetails.value = updateVendorType.beneficiary
      } else {
        shouldCreateOrUpdateVendor.value = 'create'
      }
    }
  },
)

const emit = defineEmits(['close'])

const closeAddvendor = () => {
  emit('close')

  shouldCreateOrUpdateVendor.value = 'create'
  consentAgreed.value = false

  if (createBillVendor.value) {
    createBillVendor.value = false
    $event('open:vendor', { trigger: false, createNewVendorForBill: false })
  }
}

const selectedBill = computed(() => billStore.selectedBill)

const organisationId = computed(() => profile.data?.organisation?.id)

// watch(isCreated, (newValue: boolean) => {
//   if (newValue) {
//     console.log('here - add vendor')
//     const { $event } = useNuxtApp()
//     $event('load:vendor', { trigger: true })
//   }
// })

const handleCreateUpdateVendor = async () => {
  if (shouldCreateOrUpdateVendor.value === 'create') {
    await createLocalVendor()
  } else {
    await updateVendor()
  }
}

const proofOfAccountLocal = ref<File>()

const createLocalVendor = async () => {
  try {
    toast({
      title: 'Vendor creation in progress...',
      description: " You will be notified once it's completed",
      variant: 'default',
    })
    isLoading.value = true
    const formDataArray = []
    const bankCountry = {
      key: 'bank_country_code',
      path: 'beneficiary.bank_details.bank_country_code',
      required: true,
      value: 'GB',
    }
    const bankCurrency = {
      key: 'account_currency',
      path: 'beneficiary.bank_details.account_currency',
      required: true,
      value: 'GBP',
    }
    const entityType = {
      key: 'entity_type',
      path: 'beneficiary.entity_type',
      required: true,
      value: 'COMPANY',
    }
    const paymentMethod = {
      key: 'payment_method',
      path: 'payment_method',
      required: true,
      value: 'LOCAL',
    }
    const localClearingSystem = {
      key: 'local_clearing_system',
      path: 'beneficiary.bank_details.local_clearing_system',
      required: false,
      value: 'FASTER_PAYMENTS',
    }
    formDataArray.push(
      entityType,
      bankCountry,
      bankCurrency,
      paymentMethod,
      localClearingSystem,
    )
    const dynamicInput = [
      {
        key: 'company_name',
        path: 'beneficiary.company_name',
        required: true,
        value: localData.value.name,
      },
      {
        key: 'account_routing_type1',
        path: 'beneficiary.bank_details.account_routing_type1',
        required: true,
        value: 'sort_code',
      },
      {
        key: 'account_routing_value1',
        path: 'beneficiary.bank_details.account_routing_value1',
        required: true,
        value: localData.value.sort_code,
      },
      {
        key: 'bank_name',
        path: 'beneficiary.bank_details.bank_name',
        required: true,
        value: localData.value.bank_name,
      },
      {
        key: 'account_number',
        path: 'beneficiary.bank_details.account_number',
        required: true,
        value: localData.value.account_number,
      },
      {
        key: 'account_name',
        path: 'beneficiary.bank_details.account_name',
        required: true,
        value: localData.value.bank_name,
      },
      {
        key: 'country_code',
        path: 'beneficiary.address.country_code',
        required: true,
        value: 'GB',
      },
      {
        key: 'postcode',
        path: 'beneficiary.address.postcode',
        required: true,
        value: localData.value.postal_code,
      },
      {
        key: 'street_address',
        path: 'beneficiary.address.street_address',
        required: true,
        value: localData.value.address,
      },
      {
        key: 'city',
        path: 'beneficiary.address.city',
        required: true,
        value: localData.value.city,
      },
      {
        key: 'personal_email',
        path: 'beneficiary.additional_info.personal_email',
        required: false,
        value: localData.value.email,
      },
      {
        key: 'business_phone_number',
        path: 'beneficiary.additional_info.business_phone_number',
        required: false,
        value: localData.value.phone_number,
      },
    ]
    for (const field of dynamicInput) {
      const formDataObject = {
        key: field.key,
        path: field.path,
        required: field.required,
        value:
          typeof field.value === 'string'
            ? field.value.replaceAll('-', '')
            : field.value,
      }
      formDataArray.push(formDataObject)
    }

    const { status } = await $lenkieBankingApi.post(
      '/Beneficiaries/v2/validate',
      {
        beneficiary_schema_key_values: formDataArray,
        create_beneficiary_schema_request_model: {
          account_currency: 'GBP',
          bank_country_code: 'GB',
          payment_method: 'LOCAL',
          local_clearing_system: 'FASTER_PAYMENTS',
        },
      },
    )
    if (status === 200) {
      // UPLOAD PROOF OF ACCOUNT START
      let proofOfAccountUrl: string | undefined

      if (proofOfAccountLocal.value && showConsentStatement.value) {
        const { upload } = useFileUploader()

        const result = await upload({
          files: [proofOfAccountLocal.value],
          organisationId: organisationId.value!,
          uploadType: 'vendor_proof_of_account',
        })

        if (result) {
          proofOfAccountUrl = result[0]
        }
      }
      // UPLOAD PROOF OF ACCOUNT END

      const isBusiness = localData.value.type === 'business'
      let endpoint = '/Beneficiaries/v2/create'
      if (selectedBill.value !== null) {
        endpoint += `?beneficiaryId=${selectedBill.value.id}&isBusiness=${isBusiness}`
      } else {
        endpoint += `?isBusiness=${isBusiness}`
      }

      const { status, data } = await $lenkieBankingApi.post(endpoint, {
        organisation_id: organisationId.value,
        user_id: personId.value,
        beneficiary_request: {
          beneficiary_schema_key_values: formDataArray,
          create_beneficiary_schema_request_model: {
            account_currency: 'GBP',
            bank_country_code: 'GB',
            payment_method: 'LOCAL',
            local_clearing_system: 'FASTER_PAYMENTS',
          },
        },
        agreed_to_save_unverified: showConsentStatement.value
          ? consentAgreed.value
          : null,
        invoice_urls: proofOfAccountUrl,
      })

      if (status === 200) {
        $event('create-vendor', { trigger: true })
        $event('track:mixpanel', {
          event: 'Supplier Created',
          data: {
            type: isLocal.value ? 'local' : 'international',
            organisation_id: organisationId.value,
            user_id: personId.value,
            organisation_name: profile.data?.organisation?.trading_name,
          },
        })
        errorMessage.value = ''

        if (data && createBillVendor.value) {
          billStore.setSelectedVendor(data)
        }

        toast({
          title: 'Vendor created.',
          description: 'We have successfully created your vendor.',
          variant: 'default',
        })
        localData.value = null

        closeAddvendor()

        isLoading.value = false
        // showVendorCreateSuccessInfo()
        onOpenSuccessModal()
      }
    }
  } catch (error: any) {
    const { $event } = useNuxtApp()
    isLoading.value = false
    if (error.isAxiosError) {
      if (error.response) {
        const failureReasons = error.response?.data?.failure_reasons
        for (const field in failureReasons) {
          if (Object.prototype.hasOwnProperty.call(failureReasons, field)) {
            failureReasons[field].forEach((error) => {
              toast({
                title: `${field}`,
                description: `${error}`,
                variant: 'destructive',
              })

              $event(
                'error:report',
                JSON.stringify(
                  `Company Id: ${profile?.data?.organisation?.id}, Company Name: ${profile?.data?.organisation?.trading_name} errors: ${field} ${error}`,
                ),
              )
            })
          }
        }
      }
    }
  } finally {
    isLoading.value = false
  }
}

const updateVendor = async () => {
  if (
    !(
      ocrVendorDetails.value &&
      (ocrVendorDetails.value.name || ocrVendorDetails.value?.trading_name) &&
      ocrVendorDetails.value.account.uk_bank_details &&
      ocrVendorDetails.value.account.uk_bank_details.account_number &&
      ocrVendorDetails.value.account.uk_bank_details.sort_code
    )
  ) {
    return
  }

  try {
    isLoading.value = true
    let proofOfAccountUrl: string | undefined

    if (proofOfAccountLocal.value && showConsentStatement.value) {
      const { upload } = useFileUploader()

      const result = await upload({
        files: [proofOfAccountLocal.value],
        organisationId: organisationId.value!,
        uploadType: 'vendor_proof_of_account',
      })

      if (result) {
        proofOfAccountUrl = result[0]
      }
    }
    const { status, data } = await $api.banking.beneficiaries.updateBeneficiary(
      {
        organisationId: organisationId.value,
        beneficiaryId: ocrVendorDetails.value?.id || '',
      },
      {
        name:
          localData.value.name ||
          ocrVendorDetails.value.name ||
          ocrVendorDetails.value?.trading_name,
        sort_code:
          localData.value.sort_code ||
          ocrVendorDetails.value.account.uk_bank_details.sort_code,
        account_number:
          localData.value.account_number ||
          ocrVendorDetails.value.account.uk_bank_details.account_number,
        agreed_to_save_unverified: showConsentStatement.value
          ? consentAgreed.value
          : null,
        invoice_urls: proofOfAccountUrl || null,
      },
    )

    if (status === 200) {
      $event('create-vendor', { trigger: true })
      $event('track:mixpanel', {
        event: 'Supplier Updated',
        data: {
          type: 'local',
          organisation_id: organisationId.value,
          user_id: personId.value,
          organisation_name: profile.data?.organisation?.trading_name,
        },
      })
      errorMessage.value = ''

      if (data && createBillVendor.value) {
        billStore.setSelectedVendor(data)
      }

      toast({
        title: 'Vendor updated.',
        description: 'We have successfully updated your vendor.',
        variant: 'default',
      })
      localData.value = null

      closeAddvendor()

      if (selectedBill.value !== null) {
        $event('bill-table:duplicate', {
          trigger: true,
          duplicateBillId: selectedBill.value.id,
        })
        $event('fetch:bills', {
          trigger: true,
        })
      }

      isLoading.value = false
    }
  } catch (error: any) {
    isLoading.value = false
    if (error.isAxiosError) {
      if (error.response) {
        const failureReasons = error.response?.data?.failure_reasons
        for (const field in failureReasons) {
          if (Object.prototype.hasOwnProperty.call(failureReasons, field)) {
            failureReasons[field].forEach((error) => {
              toast({
                title: `${field}`,
                description: `${error}`,
                variant: 'destructive',
              })

              $event(
                'error:report',
                JSON.stringify(
                  `Company Id: ${profile?.data?.organisation?.id}, Company Name: ${profile?.data?.organisation?.trading_name} errors: ${field} ${error}`,
                ),
              )
            })
          }
        }
      }
    }
  }
}

const submitForm = async () => {
  try {
    // isCreated.value = false
    if (!data.value.invoice_url) {
      return toast({
        title: 'Invoice required',
        description: 'An invoice is required to create a vendor',
        variant: 'destructive',
      })
    }
    toast({
      title: 'Vendor creation in progress...',
      description: " You will be notified once it's completed",
      variant: 'default',
    })
    isLoading.value = true
    const formDataArray = []
    const bankCountry = {
      key: 'bank_country_code',
      path: 'beneficiary.bank_details.bank_country_code',
      required: true,
      value: data.value.selected_country,
    }
    const bankCurrency = {
      key: 'account_currency',
      path: 'beneficiary.bank_details.account_currency',
      required: true,
      value: data.value.selected_currency,
    }
    const entityType = {
      key: 'entity_type',
      path: 'beneficiary.entity_type',
      required: true,
      value: 'COMPANY',
    }
    const paymentMethod = {
      key: 'payment_method',
      path: 'payment_method',
      required: true,
      value: data.value.selected_payment_method,
    }
    const localClearingSystem = {
      key: 'local_clearing_system',
      path: 'beneficiary.bank_details.local_clearing_system',
      required: false,
      value: data.value.selected_clearing_system,
    }
    formDataArray.push(
      entityType,
      bankCountry,
      bankCurrency,
      paymentMethod,
      localClearingSystem,
    )
    if (data.value.dynamic_input) {
      for (const field of data.value.dynamic_input) {
        const formDataObject = {
          key: field.key,
          path: field.path,
          required: field.required,
          value: data.value.form_data[field.key] || field.field.default,
        }
        formDataArray.push(formDataObject)
      }
    }
    const { status } = await $lenkieBankingApi.post(
      '/Beneficiaries/v2/validate',
      {
        beneficiary_schema_key_values: formDataArray,
        create_beneficiary_schema_request_model: {
          account_currency: data.value.selected_currency,
          bank_country_code: data.value.selected_country,
          payment_method: data.value.selected_payment_method,
          local_clearing_system: data.value.selected_clearing_system,
        },
      },
    )
    if (status === 200) {
      const response = await $lenkieBankingApi.post(
        `/Beneficiaries/v2/create${
          selectedBill.value !== null
            ? `?beneficiaryId=${selectedBill.value.id}`
            : ''
        }`,
        {
          organisation_id: organisationId.value,
          user_id: personId.value,
          invoice_urls: data.value.invoice_url,
          beneficiary_request: {
            beneficiary_schema_key_values: formDataArray,
            create_beneficiary_schema_request_model: {
              account_currency: data.value.selected_currency,
              bank_country_code: data.value.selected_country,
              payment_method: data.value.selected_payment_method,
              local_clearing_system: data.value.selected_clearing_system,
            },
          },
        },
      )
      if (response.status === 200) {
        // isCreated.value = true
        const { $event } = useNuxtApp()
        $event('create-vendor', { trigger: true })

        $event('track:mixpanel', {
          event: 'Supplier Created',
          data: {
            type: 'international',
            organisation_id: organisationId.value,
            user_id: personId.value,
            organisation_name: profile.data?.organisation?.trading_name,
          },
        })
        data.value.invoice_url = null
        toast({
          title: 'Vendor created.',
          description: 'We have successfully created your vendor.',
          variant: 'default',
        })

        if (data && createBillVendor.value) {
          billStore.setSelectedVendor(response.data)
        }

        closeAddvendor()
        showVendorCreateSuccessInfo()
      }
    }
  } catch (error: any) {
    const { $event } = useNuxtApp()
    isLoading.value = false
    if (error.isAxiosError) {
      if (error.response) {
        const failureReasons = error.response?.data?.failure_reasons
        for (const field in failureReasons) {
          if (Object.prototype.hasOwnProperty.call(failureReasons, field)) {
            failureReasons[field].forEach((error) => {
              toast({
                title: `${field}`,
                description: `${error}`,
                variant: 'destructive',
              })
              $event(
                'error:report',
                JSON.stringify(
                  `Company Id: ${profile?.data?.organisation?.id}, Company Name: ${profile?.data?.organisation?.trading_name} errors: ${field} ${error}`,
                ),
              )
            })
          }
        }
      }
    }
  } finally {
    isLoading.value = false
  }
}

const router = useRouter()
watch(
  () => props.open,
  (newVal) => {
    if (newVal) {
      LeadBoosterController.hide()
      return
    }
    if (router.currentRoute.value.name === 'dashboard') {
      LeadBoosterController.show()
    }
  },
)

watch(
  () => billStore.selectedBillInvoices,
  (newVal) => {
    if (newVal !== null && newVal.length > 0) {
      invoiceFiles.value = newVal
    } else {
      invoiceFiles.value = []
    }
  },
  { immediate: true, deep: true },
)

function showVendorCreateSuccessInfo() {
  let countStr = localStorage.getItem(
    LOCAL_STORAGE_KEYS.SEEN_VENDOR_CREATE_INFO_COUNT,
  )
  const maxCount = 1

  if (!countStr) {
    countStr = '0'
  }

  const countNum = parseInt(countStr)

  if (countNum < maxCount) {
    setModalState({ isOpen: true, type: 'vendorCreationSuccessMessage' })
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.SEEN_VENDOR_CREATE_INFO_COUNT,
      (countNum + 1).toString(),
    )
  }
}

const consentAgreed = ref(false)
const localFormPayeeConfirmationResult = ref<ConfirmPayeeDetailsResponse>()
const showConsentStatement = computed(() => {
  if (!localFormPayeeConfirmationResult.value) return false

  const { is_full_match, is_partial_match } =
    localFormPayeeConfirmationResult.value
  return !is_full_match || is_partial_match
})

watch(localFormPayeeConfirmationResult, () => {
  consentAgreed.value = false
})
const forceAllowLocalVendorCreation = ref(false)

const formType = ref<'local' | 'international'>('local')

watch(
  () => [props.open, formType.value] as const,
  (newVal) => {
    const [isOpen, _formType] = newVal
    if (isOpen && shouldCreateOrUpdateVendor.value === 'create') {
      $event('track:mixpanel', {
        event: `Supplier form opened`,
        data: { type: _formType },
      })
    }
  },
  { immediate: true },
)
</script>
