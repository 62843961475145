<template>
  <div class="relative rounded-lg border bg-white p-6 pb-7">
    <div class="border-b pb-4 text-xl font-bold text-primary">
      Apply for funding
    </div>
    <div class="mt-[25px]">
      <Label for="invoice-id" class="mb-1.5 text-sm font-medium text-primary">
        How much funding do you require?</Label
      >
      <Input
        id="amount"
        v-model="amount"
        v-cleave="{
          prefix: '£',
          numeral: true,
          numeralPositiveOnly: true,
          signBeforePrefix: true,
          numeralDecimalMark: '.',
          delimiter: ',',
        }"
        type="text"
        placeholder="Enter amount"
      />
    </div>
    <div class="mt-[25px]">
      <div>
        <Label for="funding" class="mb-1.5 text-sm font-medium text-primary"
          >Why do you need funding?</Label
        >
        <Textarea
          id="why_funding"
          v-model="why_funding"
          type="text"
          placeholder=""
        ></Textarea>
      </div>
    </div>
    <div v-click-outside="handleClickOutside" class="mt-[25px]">
      <Label class="mb-1.5 text-sm font-medium text-primary">
        When do you intend to start using the funding?
      </Label>
      <Button
        id="date"
        :variant="'outline'"
        :class="cn('mt-1.5 w-full justify-start text-left font-normal')"
        @click="isDateOpen = !isDateOpen"
      >
        <CalendarIcon class="mr-2 h-4 w-4" />
        <span>
          {{ dayjs(datePicked).format('DD-MM-YYYY') }}
        </span>
      </Button>
      <div v-if="isDateOpen" class="absolute top-20 bg-white">
        <VDatePicker
          v-model="datePicked"
          transparent
          borderless
          :min-date="minDate"
          :max-date="maxDate"
        ></VDatePicker>
      </div>
    </div>

    <div class="mt-[25px]">
      <Button
        :disabled="isLoading || !amount || !why_funding"
        variant="default"
        class="item-center flex w-full"
        @click.prevent="requestFunding"
      >
        {{ isLoading ? 'Sending request...' : 'Send request' }}</Button
      >
    </div>
  </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { Calendar as CalendarIcon } from 'lucide-vue-next'
import { useMutation } from '@tanstack/vue-query'
import { useProfileStore } from '~/stores/profile'
import { Textarea } from '@/components/ui/textarea'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { useAuthStore } from '~/stores/auth'
import { setModalState } from '~/services/modal'
import { useToast } from '~/components/ui/toast'
import type {
  ErrorResponse,
  IFundingRequest,
} from '~/types/apiPayload/requestFunding.payload'

const isDateOpen = ref(false)
const amount = ref()
const why_funding = ref()
const datePicked = ref(new Date())
datePicked.value.setDate(datePicked.value.getDate() + 1)
const auth = useAuthStore()
const profile = useProfileStore()
const { toast } = useToast()
const { $api, $event } = useNuxtApp()

const { personId } = storeToRefs(auth)

const minDate = computed(() => {
  const tomorrow = new Date()
  return tomorrow.setDate(tomorrow.getDate() + 1)
})

const maxDate = computed(() => {
  const maxDay = new Date()
  return maxDay.setDate(maxDay.getDate() + 30)
})

watch(datePicked, (newVal) => {
  if (newVal) {
    isDateOpen.value = false
  }
})

const handleClickOutside = () => {
  isDateOpen.value = false
}

const { mutate, isPending: isLoading } = useMutation({
  mutationFn: (payload: IFundingRequest) =>
    $api.banking.requestForFunding.requestForAdditionFunding(payload),
  onSuccess: () => {
    $event('update:credit-facility', null)
    setModalState({ isOpen: false, type: 'getFunding' })
    toast({
      title: 'Your request was successful',
      variant: 'default',
      description: `Your request has been successfully received. We will get back to you shortly`,
    })
  },
  onError: (error: ErrorResponse) => {
    if (error.response && error.response.data.failure_reasons) {
      $event('update:credit-facility', null)
      const failure = error.response.data.failure_reasons
      for (const key in failure) {
        if (Object.hasOwnProperty.call(failure, key)) {
          const errorMessage = failure[key]
          errorMessage.value =
            errorMessage || 'An error occured, please try again'
          toast({
            title: 'Request failed',
            variant: 'destructive',
            description: `${errorMessage}`,
          })
          break
        }
      }
    }
    setModalState({ isOpen: false, type: 'getFunding' })
  },
})
function requestFunding() {
  const payload: IFundingRequest = {
    person_id: personId.value!,
    organisation_id: `${profile?.data?.organisation?.id}`,
    amount_requested_for: `${amount.value?.replace('£', '').replace(',', '')}`,
    currency_code: 'GPB',
    reason_for_funding: `${why_funding.value}`,
    fund_usage_start_date: `${dayjs(datePicked.value).format('YYYY-MM-DD')}`,
    invoice_or_supporting_document_urls: null,
  }
  mutate(payload)
}
</script>
