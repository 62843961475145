<template>
  <div class="rounded-lg border bg-white px-7 pb-12 pt-6">
    <div>
      <img
        class="mx-auto rounded-full bg-[#E7EEFF] p-3"
        src="@/assets/svgs/p2fa.svg"
      />
    </div>
    <div class="mt-4 text-center">
      <DialogTitle as="h2" class="font-bold text-primary"
        >Enable Payment Two-factor Authentication(2FA)</DialogTitle
      >
      <DialogDescription class="mt-2 text-center text-sm text-[#667085]">
        Enter a 6-digit code sent to
        <span>*** {{ getLastDigits(emailPhoneOrNumber) }}</span>
      </DialogDescription>
      <opt-input
        :fields="fields"
        :loading="loading"
        :error="errors"
        @update:model-value="submit($event)"
      ></opt-input>
    </div>
  </div>
</template>

<script setup lang="ts">
import OptInput from '@/components/shared/opt-input.vue'
import { useAuthStore } from '~/stores/auth/index'
import { useToast } from '@/components/ui/toast'
import { setModalState } from '@/services/modal'

const fields = ref(6)

const auth = useAuthStore()
const { personId } = storeToRefs(auth)
const { $lenkieApi } = useNuxtApp()
const { toast } = useToast()
const optLocal = localStorage.getItem('opt')
const loading = ref(false)

const errors = ref('')

const emailPhoneOrNumber = ref(localStorage.getItem('email_phone'))

function getLastDigits(str: string | null) {
  if (str !== null) {
    return str?.substring(3)
  }
}

const submit = async ($event: Number) => {
  try {
    if ($event.toString().length === 6) {
      loading.value = true
      const opt = $event
      const { status } = await $lenkieApi.put(
        `/TwoFactorAuthenticator/change-2fa-channel`,
        {
          user_id: personId.value,
          channel: optLocal === 'SendToEmail' ? 'Email' : 'Sms',
          otp: opt,
        },
      )
      if (status === 200) {
        toast({
          title: 'Successful',
          description: `You have successfully changed to ${optLocal === 'SendToEmail' ? 'Email' : 'Sms'}.`,
          variant: 'default',
        })
        const { $event } = useNuxtApp()
        $event('otp', { trigger: true })
        setModalState({ isOpen: false, type: 'twoFa' })
      }
    }
  } catch (error) {
    loading.value = false
    errors.value =
      error?.response?.data?.error_message ||
      'The code you entered has expired.'
    setModalState({ isOpen: false, type: 'twoFa' })
    toast({
      title: 'Error',
      description: `${error?.response?.data?.error_message}`,
      variant: 'destructive',
    })
  }
}
</script>
