<script setup lang="ts">
import { useIdle, useBroadcastChannel } from '@vueuse/core'
import dayjs from 'dayjs'
import CONSTANTS, { LOCAL_STORAGE_KEYS } from '@/constants/CONSTANTS'
import { useAuthStore } from '@/stores/auth'
import MyWorker from '@/assets/workers/worker?worker'
import { logout } from '~/lib/utils'
const worker = new MyWorker()

const authStore = useAuthStore()
const showTimeoutModal = ref(false)

type BroadcastMessages = 'logout' | 'closeCountDownTimer'

const {
  isSupported: isBroadcastChannelSupported,
  post: broadcastPost,
  channel,
} = useBroadcastChannel<BroadcastMessages, BroadcastMessages>({
  name: 'auth-channel',
})

const { lastActive } = useIdle(CONSTANTS.INACTIVITY_TIME_ALLOWED)

async function logoutUser() {
  authStore.clearUserSession()
  await logout()
}

async function handleLogout() {
  try {
    if (isBroadcastChannelSupported.value) {
      broadcastPost('logout')
    }
    await logoutUser()
  } catch (error: any) {}
}

function resetModal() {
  showTimeoutModal.value = false
}

watch(lastActive, (_newVal) => {
  updateLastActiveTime()
})

onMounted(() => {
  updateLastActiveTime()

  worker.postMessage({
    name: 'startTimer',
    intervalTime: CONSTANTS.INACTIVITY_TIME_ALLOWED,
  })

  worker.onmessage = (event) => {
    if (event.data.name === 'tick') {
      checkInactivity()
    }
  }

  channel.value!.onmessage = async (event) => {
    const data = event.data
    if (data === 'logout') {
      await logoutUser()
    }

    if (data === 'closeCountDownTimer') {
      resetModal()
    }
  }
})

function updateLastActiveTime() {
  const currentTime = Date.now()
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.LAST_ACTIVE_TIMESTAMP,
    currentTime.toString(),
  )
}

function checkInactivity() {
  const currentTime = Date.now()
  const lastActiveTime = localStorage.getItem(
    LOCAL_STORAGE_KEYS.LAST_ACTIVE_TIMESTAMP,
  )

  if (!lastActiveTime) return

  const lastActiveTimeInt = parseInt(lastActiveTime)
  const diff = dayjs(currentTime).diff(dayjs(lastActiveTimeInt), 'millisecond')

  if (diff >= CONSTANTS.INACTIVITY_TIME_ALLOWED) {
    showTimeoutModal.value = true
  }
}

onBeforeUnmount(() => {
  worker.postMessage({ name: 'stopTimer' })
  worker.terminate()
})
</script>

<template>
  <div>
    <BaseModal
      :show="showTimeoutModal"
      :show-heading="false"
      padding="p-6"
      class="z-[300]"
      :can-close="true"
      @close-modal="
        () => {
          showTimeoutModal = false
          broadcastPost('closeCountDownTimer')
        }
      "
    >
      <SharedModalsViewsTimeoutCountDown
        @stay-on="
          () => {
            showTimeoutModal = false
            broadcastPost('closeCountDownTimer')
          }
        "
        @logout="handleLogout"
      />
    </BaseModal>
  </div>
</template>
