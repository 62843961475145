<template>
  <Dialog :open="open" @update:open="closeModal">
    <DialogContent
      :class="`${modalState.type === 'fileUpload' ? 'max-w-[650px]' : ''} ${modalState.type === 'newBill' ? 'max-w-[870px]' : ''} ${modalState.type === 'billVideos' || modalState.type === 'welcome' ? 'max-w-[710px]' : ''} ${modalState.type === 'vendorCreationSuccessMessage' ? 'max-w-[640px]' : ''} z-[2800000000] border-none bg-transparent p-4 shadow-none outline-none ring-0 lg:p-6`"
    >
      <DialogTitle class="hidden" />
      <DialogDescription class="hidden" />
      <DialogClose
        v-if="modalState.type === 'welcome' && canCloseWelcome"
        class="group absolute right-3 top-3 rounded-md p-0.5 transition-colors hover:bg-secondary"
      >
        <X class="h-4 w-4 text-white group-hover:text-black" />
        <span class="sr-only">Close</span>
      </DialogClose>
      <div v-if="modalState.type !== 'welcome'">
        <DialogClose
          v-if="
            modalState.type !== 'verifyConnection' &&
            modalState.type !== 'connectionFailed' &&
            modalState.type !== 'connectionStarted' &&
            modalState.type !== 'connectionSuccess' &&
            modalState.type !== 'newBill'
          "
          class="group absolute right-3 top-3 rounded-md p-0.5 transition-colors hover:bg-secondary"
        >
          <X class="h-4 w-4 text-white group-hover:text-black" />
          <span class="sr-only">Close</span>
        </DialogClose>
      </div>
      <div class="my-3">
        <template v-if="modalState.type === 'backmarket'">
          <backmarket-modal ref="childComponentRef" @close-modal="closeModal" />
        </template>
        <template v-if="modalState.type === 'verifyConnection'">
          <verify-connection @close-modal="closeModal" />
        </template>
        <template v-if="modalState.type === 'connectionFailed'">
          <connection-failed @close-modal="closeModal" />
        </template>
        <template v-if="modalState.type === 'connectionStarted'">
          <connection-started @close-modal="closeModal" />
        </template>
        <template v-if="modalState.type === 'connectionSuccess'">
          <connection-successful @close-modal="closeModal" />
        </template>
        <template v-if="modalState.type === 'inviteMembers'">
          <invite-members />
        </template>
        <template v-if="modalState.type === 'stripeSuccess'">
          <stripe-success />
        </template>
        <template v-if="modalState.type === 'fileUpload'">
          <file-upload />
        </template>
        <template v-if="modalState.type === 'twoFa'">
          <two-fa />
        </template>
        <template v-if="modalState.type === 'openbanking'">
          <open-banking />
        </template>
        <template v-if="modalState.type === 'addbank'">
          <add-bank />
        </template>
        <template v-if="modalState.type === 'stripecard'">
          <stripe-card />
        </template>
        <template v-if="modalState.type === 'repayCalc'">
          <repayment-calculator />
        </template>
        <template v-if="modalState.type === 'newBill'">
          <new-bill />
        </template>
        <template v-if="modalState.type === 'getFunding'">
          <get-funding />
        </template>
        <template v-if="modalState.type === 'billVideos'">
          <bill-videos />
        </template>
        <template v-if="modalState.type === 'welcome'">
          <welcome />
        </template>
        <template v-if="modalState.type === 'archiveBill'">
          <archiveBill />
        </template>
        <template v-if="modalState.type === 'addBankAdvance'">
          <SharedModalsViewsAddBankWithConnection />
        </template>
        <template v-if="modalState.type === 'vendorCreationSuccessMessage'">
          <SharedModalsViewsVendorCreationSuccessMessage />
        </template>
        <template v-if="modalState.type === 'bulkBillSuggestion'">
          <BulkBillSuggestionModal />
        </template>
      </div>
    </DialogContent>
  </Dialog>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { X } from 'lucide-vue-next'
import BackmarketModal from './views/backmarket-modal.vue'
import VerifyConnection from './views/verify-connection.vue'
import ConnectionFailed from './views/connection-failed.vue'
import ConnectionStarted from './views/connection-started.vue'
import FileUpload from './views/file-upload.vue'
import ConnectionSuccessful from './views/connection-successful.vue'
import InviteMembers from './views/invite-members.vue'
import StripeSuccess from './views/stripe-success.vue'
import TwoFa from './views/two-fa.vue'
import OpenBanking from './views/open-banking.vue'
import AddBank from './views/add-bank.vue'
import NewBill from './views/new-bill.vue'
import GetFunding from './views/get-funding.vue'
import RepaymentCalculator from './views/repayment-calculator.vue'
import StripeCard from './views/stripe-card.vue'
import BillVideos from './views/bill-videos.vue'
import Welcome from './views/the-welcome.vue'
import archiveBill from './views/archive-bill.vue'
import BulkBillSuggestionModal from './views/BulkBillSuggestionModal.vue'
import { Dialog, DialogTitle, DialogContent } from '@/components/ui/dialog'
import { modalState, setModalState } from '@/services/modal'
import { toast } from '~/components/ui/toast'

const modalScreens = ref({
  null: 'null',
  connectionFailed: 'connectionFailed',
  backmarket: 'backmarket',
  verifyConnection: 'verifyConnection',
  connectionStarted: 'connectionStarted',
  connectionSuccess: 'connectionSuccess',
  inviteMembers: 'inviteMembers',
  fileUpload: 'fileUpload',
  stripeSuccess: 'stripeSuccess',
  twoFa: '2ftwoFaa',
  openbanking: 'openbanking',
  addbank: 'addbank',
  stripecard: 'stripecard',
  repayCalc: 'repayCalc',
  newBill: 'newBill',
  getFunding: 'getFunding',
  billVideos: 'billVideos',
  welcome: 'welcome',
  archiveBill: 'archiveBill',
  addBankAdvance: 'addBankAdvance',
  vendorCreationSuccessMessage: 'vendorCreationSuccessMessage',
  bulkBillSuggestion: 'bulkBillSuggestion',
})

const canCloseWelcome = ref(false)

const open = computed(
  () =>
    modalState.value.isOpen &&
    Object.keys(modalScreens.value).includes(modalState.value.type),
)

const closeModal = () => {
  if (
    modalState.value.type !== 'verifyConnection' &&
    modalState.value.type !== 'connectionStarted'
  ) {
    if (modalState.value.type === 'welcome') {
      if (canCloseWelcome.value) {
        setModalState({ isOpen: false, type: modalState.value.type })
      } else {
        toast({
          title: 'Please watch the onboarding video',
        })
      }
    } else {
      setModalState({ isOpen: false, type: modalState.value.type })
    }
  }
}

const tenSecondsTimeout = 10000
watch(
  () => modalState.value.type,
  (newVal) => {
    if (newVal === 'welcome') {
      setTimeout(() => {
        canCloseWelcome.value = true
      }, tenSecondsTimeout)
    }
  },
)
</script>
