<template>
  <div class="rounded-lg bg-white p-6">
    <div class="h-[274px] w-full">
      <img
        :src="BulkSuggestGif"
        alt="Lenkie bulk payment feature image gif"
        class="size-full rounded-lg object-cover"
      />
    </div>
    <div class="mt-7 text-xl font-bold text-primary">
      Save Time with Bulk Payments
    </div>
    <div class="mt-2.5 text-sm text-primary">
      Easily make multiple payments at once with our bulk payment feature. Save
      time and simplify your payment process.
    </div>
    <Button class="mt-4" @click="closeModal">Ok, I’ll give it a try</Button>
  </div>
</template>

<script setup lang="ts">
import BulkSuggestGif from '@/assets/images/bulk-suggest.gif'
import { setModalState } from '~/services/modal'

function closeModal() {
  setModalState({ isOpen: false, type: 'bulkBillSuggestion' })
}
</script>
